.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	width: max(16vmax, 150px);
	background: var(--profile);
	border-right: solid 1.5px var(--profile-border);
	z-index: 2;
}

.burgerContainer {
	display: none;
}

.subContainer {
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
}

.profilePic {
	border-radius: 100%;
	background-color: white;
	display: flex;
	margin: 0 auto;
	width: clamp(5rem, 6vmax, 10rem);
}

.name {
	margin: 1vmax 0 5vh;
	font-weight: 700;
	font-size: clamp(1.25rem, 1.2vmax, 2.25rem);
	text-align: center;
}

.themeSwitch {
	display: flex;
	align-items: center;
	flex-direction: column;
	font-size: clamp(0.75rem, 0.65vmax, 1rem);
	margin-bottom: 2vmax;
	cursor: pointer;
}

.themeSwitch svg {
	width: min(3vmin, 30px);
}

.themeSwitch p {
	margin-top: min(0.7vmax, 15px);
}

@media (max-width: 750px) {
	.container {
		position: absolute;
		height: 100vh;
		height: -webkit-fill-available;
		width: 100vw;
		border-radius: 0;
		border: none;
		z-index: 100;
		background: var(--background);
		color: var(--contrast-text);
		transform: translateX(100%);
		transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
		justify-content: space-between;
		opacity: 0.5;
	}

	.active {
		transform: translateY(0%);
		opacity: 1;
	}

	.burgerContainer {
		display: block;
		height: 8vh;
		width: 100vw;
	}

	.themeSwitch svg {
		width: 1.75rem;
	}
}
