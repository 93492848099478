.hamburger {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 8vh;
	cursor: pointer;
	transition-property: opacity, filter;
	transition-duration: 0.15s;
	transition-timing-function: linear;
	width: 100vw;
	position: fixed;
	z-index: 1000;
}

.hamburger:hover {
	opacity: 0.7;
}

.hamburger.isActive:hover {
	opacity: 0.7;
}

.hamburger.isActive .hamburgerInner,
.hamburger.isActive .hamburgerInner::before,
.hamburger.isActive .hamburgerInner::after {
	background-color: var(--contrast-text);
}

.hamburgerBox {
	width: 40px;
	height: 24px;
	display: inline-block;
	position: relative;
}

.hamburgerInner {
	display: block;
	top: 50%;
	margin-top: -2px;
}
.hamburgerInner,
.hamburgerInner::before,
.hamburgerInner::after {
	width: 40px;
	height: 4px;
	background-color: var(--contrast-text);
	border-radius: 4px;
	position: absolute;
	transition-property: transform;
	transition-duration: 0.15s;
	transition-timing-function: ease;
}
.hamburgerInner::before,
.hamburgerInner::after {
	content: '';
	display: block;
}
.hamburgerInner::before {
	top: -10px;
}
.hamburgerInner::after {
	bottom: -10px;
}

.hamburger.isActive .hamburgerInner::before {
	transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

.hamburger.isActive .hamburgerInner::after {
	transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

@media (min-width: 751px) {
	.hamburger {
		display: none;
	}
}
