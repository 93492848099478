body {
	font-family: 'Poppins', sans-serif;
	overflow: hidden;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

button:focus {
	outline: none;
}
