.root {
	height: 100vh !important;
}

@media (max-width: 750px) {
	.root {
		height: -webkit-fill-available !important;
        margin-top: 8vh;
	}
}
