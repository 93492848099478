.container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	grid-column: 1 / -1;
}

.container h1 {
	font-weight: 700;
	font-size: 2.5rem;
	color: var(--header-text);
}

@media (max-width: 750px) {
	.container {
		flex-direction: column;
	}
}
