.container {
	display: flex;
	align-items: center;
}

.container svg {
	width: clamp(1.75rem, 1.75vmax, 1.8rem);
	height: clamp(1.75rem, 1.75vmax, 1.8rem);
	padding-right: 8px;
	fill: var(--secondary-text);
}
