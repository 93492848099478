.button {
	width: 48%;
	background: var(--btn);
	border-radius: 2px;
	border: none;
	color: var(--contrast-text);
	font-weight: 300;
	font-family: 'Poppins', sans-serif;
	padding: 4px 10px;
	cursor: pointer;
}

.button + .button {
	margin-left: 10px;
}

.button:hover {
	background: var(--btn-hover);
	color: var(--secondary-text);
}
