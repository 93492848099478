.projectsContainer {
	display: grid;
	grid-template-columns: repeat(auto-fit, 28ch);
	grid-template-rows: 10vmin repeat(auto-fit, 28ch);
	grid-auto-rows: 28ch;
	justify-content: center;
	gap: clamp(30px, 3vmin, 45px);
	width: min(80vw, 1500px);
	margin: auto;
    margin-bottom: 20px;
}

.arrow {
	height: 5vmin;
}

.arrow svg {
	width: 3vmin;
	display: flex;
	margin: 15px auto 0px;
	fill: var(--header-text);
}

.lastRow {
	height: 10px;
	grid-column: 1 / -1;
}
@media (max-width: 750px) {
	.projectsContainer {
		grid-template-columns: repeat(auto-fit, 30ch);
		grid-template-rows: 100px repeat(auto-fit, 30ch);
		grid-auto-rows: 30ch;
		width: 100vw;
	}

	.arrow {
		display: none;
	}
}
