.container {
	position: relative;
}
.filterBtn {
	font-family: 'Poppins', sans-serif;
	font-size: 1rem;
	color: var(--faded-text);
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 2px;
	width: clamp(200px, 15vmax, 300px);
	padding: 0.2em 1.2em;
	border: 1px solid var(--profile-border);
	background: var(--filterBar);
	backdrop-filter: blur(100px);
	position: relative;
	z-index: 20;
	cursor: pointer;
}

.filterBtn svg {
	width: 1em;
	height: 0.6em;
	transition: transform 0.2s;
}

.filterBtn path {
	fill: var(--primary-text);
}
