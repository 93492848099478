.container {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.container p {
	font-size: clamp(1.12rem, 1vmax, 1.75rem);
	color: var(--primary-text);
	margin: clamp(0.75rem, 2.5vmin, 3rem) 0;
}

.container svg {
	width: clamp(1.75rem, 3.25vmin, 3.25rem);
	height: clamp(1.75rem, 3.25vmin, 3.25rem);
	margin-right: min(7vmax, 2rem);
}

.container use {
	fill: url(#gradient) currentColor;
}

.container:hover p {
    filter: brightness(20%);
}

.container:hover use {
    filter: brightness(90%)
}
@media (max-width: 750px) {
	.container p {
		color: var(--contrast-text);
	}

	.container use {
		fill: currentColor;
	}

	.container:hover p,
	.container:hover use {
		opacity: 0.6;
        filter: brightness(100%);
	}
}
