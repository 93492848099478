.container {
	overflow: hidden;
	position: absolute;
	display: flex;
	height: 100vh;
	width: 100vw;
	background: var(--background);
	background-repeat: no-repeat;
	background-size: cover;
	color: var(--primary-text);
	height: -webkit-fill-available;
}

.dark {
	--background: linear-gradient(252.44deg, #174463 0%, #342a55 70%);

	--profile: linear-gradient(110deg, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0.1) 100%);
	--profile-border: rgba(255, 255, 255, 0);

	--filterBar: linear-gradient(
		113.42deg,
		rgba(255, 255, 255, 0.651) 0%,
		rgba(255, 255, 255, 0.438) 100%
	);
    
	--dropdown: linear-gradient(134deg, #958cb4 0%, #768fb9 100%);
	--dropdown-hover: linear-gradient(134deg, #8d7fb6 0%, #5b7aaa 100%);

    --project-background: rgba(32, 32, 32, 0.507);

	--btn: #3a2b70;
    --btn-hover: linear-gradient(134deg, #524674 0%, #375077 100%);

	--primary-text: rgba(37, 28, 68, 0.8);
	--secondary-text: #b3d0f5;
	--faded-text: #283e69;
	--header-text: #9c93bd;
	--contrast-text: rgba(255, 255, 255, 0.95);

	--social-stop-1: rgba(60, 48, 102, 0.95);
	--social-stop-2: rgba(34, 50, 77, 0.95);

	--switch-stop-1: #ffcdb8;
	--switch-stop-2: #bfb3ff;
}

.light {
	--background: linear-gradient(252.44deg, #e5d7ff 0%, #ffd7c6 100%);

	--profile: linear-gradient(110deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.164) 100%);
	--profile-border: rgb(255, 255, 255);

	--filterBar: linear-gradient(
		113.42deg,
		rgba(255, 255, 255, 0.918) 0%,
		rgba(255, 255, 255, 0.678) 100%
	);

	--dropdown: rgb(255, 238, 231);
	--dropdown-hover: linear-gradient(134deg, #f4e1ff 0%, #ffe4d7 100%);

    --project-background: rgba(247, 244, 244, 0.829);

	--btn: #dcc8e8;
    --btn-hover: linear-gradient(134deg, #ecd0fc 0%, #ffd6c3 100%);

	--primary-text: #554475;
	--secondary-text: #564180;
	--faded-text: #8a78af;
	--header-text: #68568c;
	--contrast-text: #433670;

	--social-stop-1: #d6b8e7;
	--social-stop-2: #fce0bd;

	--switch-stop-1: #322462;
	--switch-stop-2: #1a5780;
}
