.container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	/* background: linear-gradient(
		92.43deg,
		rgba(255, 255, 255, 0.78) 2.38%,
		rgba(255, 255, 255, 0.79) 98.42%
	); */
    background: var(--project-background);
	box-shadow: 1.7vmin 1.7vmin 1.5vmin rgba(0, 0, 0, 0.1);
	border-radius: 2px;
	align-items: center;
}

.container img {
	border-radius: 2px 2px 0 0;
	width: 100%;
	height: 45%;
	object-fit: cover;
	object-position: 74.5% 59%;
}

.detailsContainer {
	margin: 0 15px;
}

.detailsContainer h2 {
	color: var(--secondary-text);
	font-weight: 400;
	font-size: 1.25rem;
}

.detailsContainer p {
	color: var(--secondary-text);
	font-size: 0.8rem;
}

.btnContainer {
	box-sizing: border-box;
	width: 100%;
	display: flex;
	padding: 0 15px 10px;
	justify-content: center;
}
