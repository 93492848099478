.container {
	/* width: 80%; */
	margin: 20px auto;
	margin-bottom: 0;
	z-index: 10;
}

@media (max-width: 750px) {
	.container {
		min-width: 100%;
		/* height: 100vh; */
		/* overflow: hidden; */
		/* margin-top: 8vh; */
		/* height: -webkit-fill-available; */
	}
}
