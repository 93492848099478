.container {
	background: var(--dropdown);
	border-radius: 2px;
	position: absolute;
	top: clamp(80px, 9vmin, 100px);
	width: clamp(200px, 15vmax, 300px);
	margin: min(-33px, -1.25vmax) 0;
	color: var(--faded-text);
	transition: all 0.1s ease-in;
}

.container ul:before {
	content: '';
	width: 0;
	height: 0;
	position: absolute;
	bottom: 100%;
	right: 1.2em;
	border-width: 0 0.5em 0.5em;
	border-style: solid;
	border-color: var(--pointer) transparent;
}

.container li {
	list-style: none;
	padding: 0.1em 1.2em;
}

.container li:not(.disabled) {
	padding-left: 2.5em;
	padding-right: 2.5em;
	cursor: pointer;
}

.container li:first-child {
	padding: 0.5em 0 0.5em 1.2em;
}

.container li:first-child:hover {
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
}

.container li:last-child {
	padding-bottom: 0.5em;
}

.container li:not(.disabled):hover {
	background: var(--dropdown-hover);
}

.container li:last-child:hover {
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
}

.disabled {
	border-top: 0.5px solid var(--faded-text);
}

.collapsed {
	opacity: 0;
	overflow: hidden;
	padding: 0;
	pointer-events: none;
}
